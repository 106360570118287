import axios from 'axios';
import { UUID } from '../domain/UUID';
import { Exam } from '../domain/Season';
import { downloadBlob } from './download';

export function downloadRegisteredCentres(examId: UUID<Exam>, examName: string): Promise<void> {
    return axios.get(`/views/csv/registeredCentres/${examId}`, { 
        responseType: 'blob'
    }).then(response => downloadBlob(response, `${examName}-registered-centres.csv`));
}

export function downloadRegisteredStudents(examId: UUID<Exam>, examName: string): Promise<void> {
    return axios.get(`/views/csv/registeredStudents/${examId}`, {
        responseType: 'blob'
    }).then(response => downloadBlob(response, `${examName}-registered-students.csv`));
}

export function downloadRegisteredMarkers(examId: UUID<Exam>, examName: string): Promise<void> {
    return axios.get(`/views/csv/registeredMarkers/${examId}`, {
        responseType: 'blob'
    }).then(response => downloadBlob(response, `${examName}-registered-markers.csv`));
}

export function downloadMarkerAssignments(examId: UUID<Exam>, examName: string): Promise<void> {
    return axios.get(`/views/csv/markerAssignments/${examId}`, {
        responseType: 'blob'
    }).then(response => downloadBlob(response, `${examName}-marker-assignments.csv`));
} 