import * as React from "react";
import Page from "../common/Page";
import AccountTable from "./AccountTable";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import SeasonRegistrationStatisticsCard from "../exams/SeasonRegistrationStatisticsCard";
import DatabaseViewsCard from "./DatabaseViewsCard";
import { useCurrentSeason } from "../exams/SeasonContext";
import { Card, Stack } from "@mui/material";

export default function AdminPage() {
  const { currentSeason } = useCurrentSeason();
  return (
    <Page
      title="Admin dashboard"
    >
      <Stack spacing={1}>
        {currentSeason && <SeasonRegistrationStatisticsCard seasonId={currentSeason?.id} />}
        {currentSeason && <DatabaseViewsCard />}
        <Card variant="outlined">
          <ul>
            <li>
              <Link component={RouterLink} to={`/seasons`}>
                Manage exam seasons
              </Link>
            </li>
            <li>
              <Link component={RouterLink} to={`/assignment`}>
                Marker assignment
              </Link>
            </li>
            <li>
              <Link component={RouterLink} to={`/email`}>
                Email
              </Link>
            </li>
          </ul>
        </Card>
        <Card variant="outlined" sx={{ border: "none" }}>
          <AccountTable />
        </Card>
      </Stack>
    </Page>
  )
}
