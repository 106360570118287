import * as React from "react";
import { useEffect, useState } from "react";
import { Alert, Box, Card, CardContent, CardHeader, Divider, Grid, Stack, Typography, } from "@mui/material";
import { getRegistrationStatistics, getSeason } from "../../gateway/exams";
import { UUID } from "../../domain/UUID";
import Season from "../../domain/Season";
import ExamLevel from "../../domain/ExamLevel";
import RegistrationStatistics from "../../domain/RegistrationStatistics";
import SchoolIcon from "@mui/icons-material/School";
import GroupIcon from "@mui/icons-material/Group";
import BarChartIcon from "@mui/icons-material/BarChart";

interface Props {
  readonly seasonId: UUID<Season>;
}

interface StatisticItemProps {
  icon: React.ReactElement;
  label: string;
  value: number | string;
}

const StatisticItem: React.FC<StatisticItemProps> = ({ icon, label, value }) => (
  <Box display="flex" alignItems="center">
    {icon}
    <Typography variant="body1">
      <strong>{label}:</strong> {value}
    </Typography>
  </Box>
);

export default function SeasonRegistrationStatisticsCard({ seasonId }: Props) {
  const [statistics, setStatistics] = useState<RegistrationStatistics | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);


  useEffect(() => {
    setLoading(true);
    getSeason(seasonId)
      .then((season) => {
        const advancedExam = season.exams[ExamLevel.ADVANCED];
        if (!advancedExam) {
          throw new Error("Advanced exam not found for the selected season.");
        }
        return getRegistrationStatistics(advancedExam.examId);
      })
      .then((data) => {
        setStatistics(data);
        setError(null);
      })
      .catch((err) => {
        setError(err.message || "Failed to load registration statistics.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [seasonId]);

  if (loading) {
    return (
      <Card variant="outlined">
        <CardHeader
          title="Advanced Registration Statistics"
          titleTypographyProps={{
            fontSize: 20,
            fontWeight: "bold",
            color: "primary.main",
          }}
        />
        <Divider />
        <CardContent>
          Loading...
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card variant="outlined">
        <CardHeader
          title="Advanced Registration Statistics"
          titleTypographyProps={{
            fontSize: 20,
            fontWeight: "bold",
            color: "primary.main",
          }}
        />
        <Divider />
        <CardContent>
          <Alert severity="error">{error}</Alert>
        </CardContent>
      </Card>
    );
  }

  if (!statistics) {
    return null;
  }

  return (
    <Card variant="outlined">
      <CardHeader
        title="Advanced Registration Statistics"
        titleTypographyProps={{
          fontSize: 20,
          fontWeight: "bold",
          color: "primary.main",
        }}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <Stack spacing={2}>
              <StatisticItem
                icon={<SchoolIcon color="primary" sx={{ mr: 1 }} />}
                label="Registered Centres"
                value={statistics.registeredCentres}
              />
              <StatisticItem
                icon={<BarChartIcon color="primary" sx={{ mr: 1 }} />}
                label="Total Centre Estimates"
                value={statistics.totalCentreEstimates}
              />
              <StatisticItem
                icon={<GroupIcon color="primary" sx={{ mr: 1 }} />}
                label="Total Registered Students"
                value={statistics.totalRegisteredStudents}
              />
            </Stack>
          </Grid>
          <Grid item sm={12} md={6}>
            <Stack spacing={2}>
              <StatisticItem
                icon={<GroupIcon color="secondary" sx={{ mr: 1 }} />}
                label="Registered Markers"
                value={statistics.registeredMarkers}
              />
              <StatisticItem
                icon={<BarChartIcon color="secondary" sx={{ mr: 1 }} />}
                label="Total Marker Estimates"
                value={statistics.totalMarkerEstimates}
              />
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
