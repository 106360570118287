// utils/download.ts
import { AxiosResponse } from 'axios';

export function downloadBlob(response: AxiosResponse, customFilename?: string): void {
  const url = window.URL.createObjectURL(response.data);

  let filename = customFilename;
  if (!filename) {
    const contentDisposition = response.headers['content-disposition'];
    if (contentDisposition && contentDisposition.includes('filename=')) {
      filename = contentDisposition.split('filename=')[1];
      filename = filename.replace(/['"]/g, '');
    } else {
      filename = 'download';
    }
  }

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;

  document.body.appendChild(a);
  a.click();
  a.remove();

  window.URL.revokeObjectURL(url);
}
