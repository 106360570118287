import * as React from "react";
import { Card, CardContent, CardHeader, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Exam } from "../../domain/Season";
import { UUID } from "../../domain/UUID";
import { downloadRegisteredCentres, downloadRegisteredStudents, downloadRegisteredMarkers, downloadMarkerAssignments } from "../../gateway/databaseViews";
import { useCurrentSeason } from "../exams/SeasonContext";
import FormInputUUIDSelection from "../form/FormInputUUIDSelection";
import { useForm } from "react-hook-form";
import ExamLevel from "../../domain/ExamLevel";

interface FormData {
  readonly examId: UUID<Exam>;
}

export default function DatabaseViewsCard() {
  const { currentSeason } = useCurrentSeason();
  const [loadingCentres, setLoadingCentres] = React.useState(false);
  const [loadingStudents, setLoadingStudents] = React.useState(false);
  const [loadingMarkers, setLoadingMarkers] = React.useState(false);
  const [loadingAssignments, setLoadingAssignments] = React.useState(false);

  if (!currentSeason) return null;

  const defaultExamId = currentSeason.exams[ExamLevel.ADVANCED]?.examId
    ?? Object.values(currentSeason.exams)[0]?.examId;

  if (!defaultExamId) return null;

  const { control, watch } = useForm<FormData>({
    defaultValues: {
      examId: defaultExamId
    }
  });

  const selectedExamId = watch("examId");
  const selectedExam = Object.values(currentSeason.exams)
    .find(exam => exam.examId === selectedExamId);
  const selectedExamName = selectedExam ? Exam.name(currentSeason, selectedExam).toLowerCase().replace(/\s+/g, '-') : '';

  const handleDownloadCentres = async () => {
    setLoadingCentres(true);
    try {
      await downloadRegisteredCentres(selectedExamId, selectedExamName);
    } finally {
      setLoadingCentres(false);
    }
  };

  const handleDownloadStudents = async () => {
    setLoadingStudents(true);
    try {
      await downloadRegisteredStudents(selectedExamId, selectedExamName);
    } finally {
      setLoadingStudents(false);
    }
  };

  const handleDownloadMarkers = async () => {
    setLoadingMarkers(true);
    try {
      await downloadRegisteredMarkers(selectedExamId, selectedExamName);
    } finally {
      setLoadingMarkers(false);
    }
  };

  const handleDownloadAssignments = async () => {
    setLoadingAssignments(true);
    try {
      await downloadMarkerAssignments(selectedExamId, selectedExamName);
    } finally {
      setLoadingAssignments(false);
    }
  };

  const examOptions = Object.values(currentSeason.exams)
    .sort((a, b) => ExamLevel.comparator()(a.level, b.level))
    .map(exam => ({
      id: exam.examId,
      name: Exam.name(currentSeason, exam)
    }));

  const isAdvancedExam = selectedExam?.level === ExamLevel.ADVANCED;

  return (
    <Card variant="outlined">
      <CardHeader
        title="CSV Exports"
        sx={{ pb: 0 }}
      />
      <CardContent>
        <Stack 
          spacing={0.5}
          direction={{ xs: 'column', md: 'row' }}
          sx={{ 
            flexWrap: 'wrap',
            gap: 1,
          }}
        >
          <FormInputUUIDSelection<FormData, Exam>
            name="examId"
            label="Select Exam"
            control={control}
            options={examOptions}
            sx={{ minWidth: 220, flexGrow: 0 }}
          />
          <LoadingButton
            variant="contained"
            loading={loadingCentres}
            onClick={handleDownloadCentres}
          >
            Registered Centres
          </LoadingButton>
          <LoadingButton
            variant="contained"
            loading={loadingStudents}
            onClick={handleDownloadStudents}
          >
            Registered Students
          </LoadingButton>
          {isAdvancedExam && (
            <>
              <LoadingButton
                variant="contained"
                loading={loadingMarkers}
                onClick={handleDownloadMarkers}
              >
                Registered Markers
              </LoadingButton>
              <LoadingButton
                variant="contained"
                loading={loadingAssignments}
                onClick={handleDownloadAssignments}
              >
                Marker Assignments
              </LoadingButton>
            </>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
} 
